import { createAction } from "@reduxjs/toolkit";

const NAMESPACE = "extension";

const updateExtensionAccountEmail = createAction<string>(
  `[${NAMESPACE}] update account email`
);
const updateExtensionTaskToken = createAction<string>(
  `[${NAMESPACE}] update task token`
);

const applyExtensionOrderAccountRequest = createAction(
  `[${NAMESPACE}] apply extension order account request`
);

const applyExtensionOrderAccountSuccess = createAction(
  `[${NAMESPACE}] apply extension order account success`
);

const applyExtensionOrderAccountFailure = createAction<{ message: string }>(
  `[${NAMESPACE}] apply extension order account failure`
);

export {
  updateExtensionAccountEmail,
  updateExtensionTaskToken,
  applyExtensionOrderAccountRequest,
  applyExtensionOrderAccountSuccess,
  applyExtensionOrderAccountFailure,
};
