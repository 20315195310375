import { createReducer } from "@reduxjs/toolkit";

import { initialState, ToastState } from "./state";
import { closeToast, showToast } from "./actions";

const handleShowToast = (
  state: ToastState,
  action: ReturnType<typeof showToast>
): ToastState => ({
  ...state,
  toasts: [...state.toasts, { toastId: state.nextId, ...action.payload }],
  nextId: state.nextId + 1,
});

const handleCloseToast = (
  state: ToastState,
  action: ReturnType<typeof closeToast>
): ToastState => ({
  ...state,
  toasts: state.toasts.filter(({ toastId }) => toastId !== action.payload),
});

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(showToast, handleShowToast)
    .addCase(closeToast, handleCloseToast)
);

export default reducer;
