import { merge } from "rxjs";
import { filter, map } from "rxjs/operators";

import { RootEpic } from "../../../../store";
import { applyExtensionOrderAccountFailure } from "../../../extension/store/actions";
import { showToast } from "../../../toast/store/actions";

const handleServiceErrorsEpic: RootEpic = (actions$) => {
  const loadRefDataFailure = actions$.pipe(
    filter(applyExtensionOrderAccountFailure.match)
  );

  return merge(loadRefDataFailure).pipe(
    map(({ payload: { message } }) =>
      showToast({
        variant: "danger",
        message,
        dismissible: true,
      })
    )
  );
};

export { handleServiceErrorsEpic };
