import extension from "../features/extension/store/reducer";

import toast from "../features/toast/store/reducer";

const reducers = {
  extension,
  toast,
};

export { reducers };
