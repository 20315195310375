import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";

import { LOGIN_CALLBACK_PATH } from "../config";

const config: OktaAuthOptions = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: `${document.location.origin}/${LOGIN_CALLBACK_PATH}`,
  scopes: ["openid", "profile", "email"],
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

export { oktaAuth };
