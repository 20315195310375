import React from "react";
import { Route, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { Col, Container, Row } from "react-bootstrap";

import HomePage from "./features/home/pages/home-page";
import ExtensionPage from "./features/extension/pages/extension-page";
import AppNavbar from "./features/app/components/app-navbar";
import RootToast from "./features/toast/components/root-toast";
import { oktaAuth } from "./services/oktaAuth";
import { LOGIN_CALLBACK_PATH } from "./config";

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <RootToast />
      <AppNavbar />
      <Container className="my-3">
        <Row>
          <Col>
            <SecureRoute path="/" exact={true}>
              <HomePage />
            </SecureRoute>
            <SecureRoute path="/extension">
              <ExtensionPage />
            </SecureRoute>
          </Col>
        </Row>
      </Container>
      <Route path={`/${LOGIN_CALLBACK_PATH}`} component={LoginCallback} />
    </Security>
  );
}

export default App;
