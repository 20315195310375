import React, { ChangeEvent, useCallback, useEffect } from "react";
import { Button, Form, FormGroup, FormText } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  applyExtensionOrderAccountRequest,
  updateExtensionAccountEmail,
  updateExtensionTaskToken,
} from "../../store/actions";
import { RootState } from "../../../../store";

const mapStateToProps = ({ extension }: RootState) => ({
  accountEmail: extension.accountEmail,
  taskToken: extension.taskToken,
});

const mapDispatchToProps = {
  updateAccountEmail: (value: string) => updateExtensionAccountEmail(value),
  updateTaskToken: (value: string) => updateExtensionTaskToken(value),
  applyExtensionOrderAccount: () => applyExtensionOrderAccountRequest(),
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ExtensionPage: React.FC<Props> = ({
  accountEmail,
  taskToken,
  updateAccountEmail,
  updateTaskToken,
  applyExtensionOrderAccount,
}) => {
  const updateAccountEmailCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      updateAccountEmail(event.target.value),
    [updateAccountEmail]
  );
  const updateTaskTokenCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      updateTaskToken(event.target.value),
    [updateTaskToken]
  );
  const resolveExtensionOrder = useCallback(
    () => applyExtensionOrderAccount(),
    [applyExtensionOrderAccount]
  );

  const { search, pathname } = useLocation();
  const { replace } = useHistory();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const email = query.get("email");
    if (email) {
      updateAccountEmail(email);
    }
    const taskToken = query.get("taskToken");
    if (taskToken) {
      updateTaskToken(taskToken);
    }

    if (search) {
      replace(pathname);
    }
  }, [pathname, replace, search, updateAccountEmail, updateTaskToken]);

  return (
    <>
      <h1 className="mb-4">Resolve License Order Extension</h1>
      <div className="text-info">
        Use this page when an order to extend a license fails to be processed
        automatically.
      </div>
      <div className="text-info">
        This usually happens when an email address in the extension order is
        different from the email address in the original order. When this
        happens the extension order workflow generates a task token to be used
        to manually map the extension order to the correct original order.
      </div>
      <hr className="bg-primary" />
      <Form>
        <FormGroup>
          <Form.Label htmlFor="accountEmailInput">Account Email</Form.Label>
          <Form.Control
            id="accountEmailInput"
            type="email"
            placeholder="Enter account email to apply the extension to"
            value={accountEmail}
            onChange={updateAccountEmailCallback}
          />
        </FormGroup>

        <FormGroup>
          <Form.Label htmlFor="taskTokenInput">Task Token</Form.Label>
          <Form.Control
            id="taskTokenInput"
            as="textarea"
            rows={4}
            placeholder="Copy the task token received from the process-order step function here"
            value={taskToken}
            onChange={updateTaskTokenCallback}
          />
        </FormGroup>

        <hr className="bg-primary" />

        <FormText className="text-warning">
          Clicking &apos;Resolve Extension Order&apos; will reprocess extension
          order pointed to by the task token, trying to apply it to the manually
          provided account.
        </FormText>
        <Button
          type="button"
          color="warning"
          className="mt-3"
          onClick={resolveExtensionOrder}
        >
          Resolve Extension Order
        </Button>
      </Form>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionPage);
