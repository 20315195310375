import React, { useCallback, useState } from "react";
import { Nav, Navbar, NavbarBrand, NavItem } from "react-bootstrap";
import { NavLink as RouterNavLink } from "react-router-dom";

import UserAccount from "../user-account";

const AppNavbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <Navbar expand="md" variant="dark">
      <NavbarBrand href="/">SQLAPI Support</NavbarBrand>
      <Navbar.Toggle onClick={toggle} />

      <Navbar.Collapse>
        <Nav className="mr-auto" navbar={true}>
          <NavItem>
            <RouterNavLink
              onClick={close}
              className="nav-link"
              to="/"
              exact={true}
            >
              Home
            </RouterNavLink>
          </NavItem>
          <NavItem>
            <RouterNavLink onClick={close} className="nav-link" to="/extension">
              Extension
            </RouterNavLink>
          </NavItem>
        </Nav>
      </Navbar.Collapse>

      <div className="d-none d-md-block">
        <UserAccount />
      </div>
    </Navbar>
  );
};

export default AppNavbar;
