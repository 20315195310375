import { createAction } from "@reduxjs/toolkit";

import { Toast } from "./state";

export type ShowToast = Omit<Toast, "toastId">;

const showToast = createAction("[Toast] show toast", (toast: ShowToast) => ({
  payload: toast,
}));

const closeToast = createAction(
  "[Toast] close toast",
  (toastId: Toast["toastId"]) => ({ payload: toastId })
);

export { showToast, closeToast };
