import React from "react";
import { Jumbotron } from "react-bootstrap";

import { OktaInfo } from "../../components/okta-info";
import { CodeRepo } from "../../components/code-repo";
import { UsefulLinks } from "../../components/useful-links";

const HomePage: React.FC = () => {
  return (
    <>
      <Jumbotron>
        <h1 className="display-3">Tier 2 Support for SQLAPI</h1>

        <p className="lead text-info">
          When automated process gets in trouble... Tier 2 squad is here to fix
          it!
        </p>

        <hr className="bg-primary my-4" />

        <h2>Useful Links</h2>
        <UsefulLinks />
      </Jumbotron>

      <Jumbotron>
        <h2>Okta Configuration</h2>
        <OktaInfo />
      </Jumbotron>

      <Jumbotron>
        <h2>Code Repositories</h2>
        <CodeRepo />
      </Jumbotron>
    </>
  );
};

export default HomePage;
