import {
  Action,
  configureStore,
  StateFromReducersMapObject,
} from "@reduxjs/toolkit";
import { createEpicMiddleware, Epic } from "redux-observable";

import { combineReducers } from "redux";
import services, { Services } from "../services";
import epic from "./root-epic";
import { reducers } from "./root-reducer";

const epicMiddleware = createEpicMiddleware<
  Action,
  Action,
  RootState,
  Services
>({
  dependencies: services,
});

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(epicMiddleware),
  reducer: combineReducers(reducers),
});
epicMiddleware.run(epic);

export type RootState = StateFromReducersMapObject<typeof reducers>;
export type RootEpic = Epic<Action, Action, RootState, Services>;

export default store;
