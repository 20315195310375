import React from "react";
import { connect } from "react-redux";
import { Alert, Toast } from "react-bootstrap";

import { RootState } from "../../../../store";
import { closeToast } from "../../store/actions";

const mapStateToProps = (state: RootState) => ({
  toasts: state.toast.toasts,
});

const mapDispatchToProps = {
  closeToast: (toastId: number) => closeToast(toastId),
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const RootToast: React.FC<Props> = ({ toasts, closeToast }) => {
  return (
    <div className="position-relative d-flex justify-content-center">
      <div className="position-absolute mt-4 z-index-toaster">
        {toasts.map((toast) => {
          const { toastId, dismissible, variant, message, header, ...props } =
            toast;
          return (
            <Toast
              className="toast-alert"
              key={toastId}
              onClose={() => closeToast(toastId)}
              show={true}
              {...props}
            >
              <Alert
                className="m-0"
                dismissible={dismissible}
                onClose={() => closeToast(toastId)}
                variant={variant}
              >
                {header && <Alert.Heading>{header}</Alert.Heading>}
                <div>{message}</div>
              </Alert>
            </Toast>
          );
        })}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RootToast);
