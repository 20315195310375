import React, { useCallback, useState } from "react";
import { MdAccountCircle as Account } from "react-icons/md";
import { Dropdown } from "react-bootstrap";
import { useOktaAuth } from "@okta/okta-react";

const UserAccount: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const logout = useCallback(async () => oktaAuth.signOut(), [oktaAuth]);
  const toggle = useCallback(
    () => setDropdownOpen((prevState) => !prevState),
    []
  );

  const claims = authState?.idToken?.claims;
  const name = claims?.name;
  const email = claims?.email;

  return (
    <Dropdown show={dropdownOpen} onToggle={toggle}>
      <Dropdown.Toggle as="div" role="button" aria-label="user avatar">
        <Account size="2rem" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Header>
          <div>{name}</div>
          <div className="small">{email}</div>
        </Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item onClick={logout} role="menuitem">
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserAccount;
