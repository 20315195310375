import React from "react";
import { FiExternalLink as LinkIcon } from "react-icons/fi";

const ExternalLink: React.FC<React.PropsWithChildren<{ href: string }>> = ({
  href,
  children,
}) => (
  <a href={href} target="_blank" rel="noreferrer">
    {children}
    <LinkIcon className="ml-2" />
  </a>
);

export { ExternalLink };
