class ServiceError extends Error {
  public readonly message;

  constructor(
    message: string,
    public readonly status?: number,
    public readonly reason?: unknown
  ) {
    super(message);

    this.message = [
      status || "",
      message || "API call to the service failed",
    ].join(" ");
    this.name = "ServiceError";
  }
}

const checkRequestError = (response: Response): Response => {
  if (!response.ok) {
    throw new ServiceError(response.statusText, response.status);
  }

  return response;
};

const handleNetworkError = (reason: unknown): never => {
  throw new ServiceError(
    "There was an error communicating with downstream services.",
    undefined,
    reason
  );
};

export { ServiceError, checkRequestError, handleNetworkError };
