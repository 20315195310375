import { createReducer } from "@reduxjs/toolkit";

import {
  updateExtensionAccountEmail,
  updateExtensionTaskToken,
} from "./actions";

type State = {
  accountEmail: string;
  taskToken: string;
};

const initialState: State = {
  accountEmail: "",
  taskToken: "",
};

function handleUpdateExtensionAccountEmail(
  state: State,
  { payload: value }: ReturnType<typeof updateExtensionAccountEmail>
): State {
  return {
    ...state,
    accountEmail: value,
  };
}

function handleUpdateExtensionTaskToken(
  state: State,
  { payload: value }: ReturnType<typeof updateExtensionTaskToken>
): State {
  return {
    ...state,
    taskToken: value,
  };
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(updateExtensionAccountEmail, handleUpdateExtensionAccountEmail)
    .addCase(updateExtensionTaskToken, handleUpdateExtensionTaskToken)
);

export default reducer;
