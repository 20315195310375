import { filter, mergeMap } from "rxjs/operators";

import { RootEpic } from "../../../../store";
import {
  applyExtensionOrderAccountRequest,
  applyExtensionOrderAccountSuccess,
  applyExtensionOrderAccountFailure,
} from "../actions";
import { ServiceError } from "../../../../services/http";
import { showToast } from "../../../toast/store/actions";

const applyExtensionOrderAccountEpic: RootEpic = (
  actions$,
  state$,
  { api }
) => {
  return actions$.pipe(
    filter(applyExtensionOrderAccountRequest.match),
    mergeMap(() => {
      const {
        extension: { accountEmail, taskToken },
      } = state$.value;
      return api
        .resolveExtension(accountEmail, taskToken)
        .then(() => [
          applyExtensionOrderAccountSuccess(),
          showToast({
            variant: "success",
            dismissible: true,
            delay: 10000,
            autohide: true,
            header: "Confirmation",
            message: `Provided account email applied to the extension order.`,
          }),
        ])
        .catch(({ message }: ServiceError) => [
          applyExtensionOrderAccountFailure({ message }),
        ]);
    }),
    mergeMap((action) => action)
  );
};

export { applyExtensionOrderAccountEpic };
