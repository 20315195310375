import React from "react";

import { oktaAuth } from "../../../../services/oktaAuth";
import { ExternalLink } from "../../../../general/components/external-link";

function oktaSite(issuerUrl: string): string {
  return issuerUrl.substr(0, issuerUrl.indexOf("/oauth2"));
}

function oktaAdminSite(siteUrl: string) {
  return siteUrl.replace(".okta.com", "-admin.okta.com");
}

const OktaInfo: React.FC = () => {
  const { clientId, issuer } = oktaAuth.options;
  const siteUrl = oktaSite(issuer ?? "");
  const adminSiteUrl = oktaAdminSite(siteUrl);

  return (
    <>
      <div className="lead">
        <ExternalLink href={siteUrl}>Portal</ExternalLink>
      </div>
      <div className="lead">
        <ExternalLink
          href={`${adminSiteUrl}/admin/app/oidc_client/instance/${clientId}`}
        >
          Tier 2 Support (this app)
        </ExternalLink>
      </div>
    </>
  );
};

export { OktaInfo };
