import React from "react";
import { IoLogoGithub as GithubIcon } from "react-icons/io";
import { FaAws as CodeCommitIcon } from "react-icons/fa";

import { ExternalLink } from "../../../../general/components/external-link";

const CodeRepo: React.FC = () => {
  return (
    <>
      <div className="lead">
        <ExternalLink href="https://github.com/pryste-sqlapi/tier2-support-ui">
          Tier 2 Support UI (this app)
          <GithubIcon className="ml-2" />
        </ExternalLink>
      </div>

      <div className="lead">
        <ExternalLink href="https://github.com/pryste-sqlapi/tier2-support-api">
          Tier 2 Support API
          <GithubIcon className="ml-2" />
        </ExternalLink>
      </div>

      <div className="lead">
        <ExternalLink href="https://github.com/pryste-sqlapi/tier-2-infra">
          Tier 2 Infra
          <GithubIcon className="ml-2" />
        </ExternalLink>
      </div>

      <div className="lead">
        <ExternalLink href="https://github.com/pryste-sqlapi/process-order-flow">
          Process Order Flow
          <GithubIcon className="ml-2" />
        </ExternalLink>
      </div>

      <div className="lead">
        <ExternalLink href="https://us-east-1.console.aws.amazon.com/codesuite/codecommit/repositories/sqlapi/browse?region=us-east-1">
          SQLAPI Site
          <CodeCommitIcon className="ml-2" />
        </ExternalLink>
      </div>

      <div className="lead">
        <ExternalLink href="https://us-east-1.console.aws.amazon.com/codesuite/codecommit/repositories/sqlapi-infra/browse?region=us-east-1">
          SQLAPI Site & CI/CD Infra
          <CodeCommitIcon className="ml-2" />
        </ExternalLink>
      </div>
    </>
  );
};

export { CodeRepo };
