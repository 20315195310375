import * as api from "./api";
import { oktaAuth } from "./oktaAuth";

const services = {
  oktaAuth,
  api,
};

export type Services = typeof services;
export default services;
