import React from "react";

import { ExternalLink } from "../../../../general/components/external-link";

const UsefulLinks: React.FC = () => {
  return (
    <>
      <div className="lead">
        <ExternalLink href="https://account.mycommerce.com/Cp/Index">
          ShareIt Control Panel
        </ExternalLink>
      </div>

      <div className="lead">
        <ExternalLink href="https://account.mycommerce.com/Home/faq">
          MyCommerce client facing FAQ
        </ExternalLink>
      </div>
    </>
  );
};

export { UsefulLinks };
