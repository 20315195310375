import { oktaAuth } from "./oktaAuth";
import { checkRequestError, handleNetworkError } from "./http";

const resolveExtension = async (
  accountEmail: string,
  taskToken: string
): Promise<Response> => {
  const endpoint = `${process.env.REACT_APP_API_URI}/extensions/resolve`;
  const accessToken = oktaAuth.getAccessToken();

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      accountEmail,
      taskToken,
    }),
  }).catch(handleNetworkError);

  return checkRequestError(response);
};

export { resolveExtension };
