export type Toast = {
  toastId: number;
  variant: "success" | "danger";
  header?: string;
  message: string;
  dismissible?: boolean;
  autohide?: boolean;
  delay?: number;
};

export type ToastState = {
  nextId: number;
  toasts: Toast[];
};

const initialState: ToastState = {
  nextId: 0,
  toasts: [],
};

export { initialState };
